import chunk from 'lodash.chunk';
import { Modal } from 'shared/components/modal/modal.component';
import { useModal } from 'app/hooks/use-modal.hook';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/elements/button.component';
import { ModalNames } from '@app/globals/constants';

interface Props {
  commodities: any;
}

export const CommoditiesModal = ({ commodities }: Props) => {
  const [, hide] = useModal(ModalNames.Commodities_Modal);
  const { t } = useTranslation();

  return (
    <Modal
      name={ModalNames.Commodities_Modal}
      onHide={hide}
      header="COMMODITY_DETAILS"
      children={
        <div className="commodity-details">
          {chunk(commodities, 1).map((pair, i) => (
            <div className="single-commodity" key={i}>
              {pair.map((item, j) =>
                <div key={j}>
                  <label>{t('PRODUCT')}</label>
                  <p className="h3">{item.commodityName !== null ? item.commodityName : t('NO_COMMODITY_INFO_AVAILABLE')}</p>
                </div>)}
            </div>
          ))}
        </div>
      }
      footer={
        <Button btnPrimary mobileResponsive className="text-right space-outer-right-lg js-settings-close-button" onClick={hide} resource="CLOSE" />
      }
    />
  );
};
